import {
  action,
  makeAutoObservable,
  makeObservable,
  observable,
  runInAction,
} from 'mobx'
import { signInRequest } from './requests/userStoreRequests';
import { IRootStore } from '.';
import { getNotesRequest, saveNoteRequest, patchNoteRequest, getNoteImageUrlsRequest } from './requests/noteStoreRequests';

class ObservableNoteStore {

  _rootStore;
  notes = [];
  currentNote = null;
  hash = 0;
  notesListTotalCount = 0;

  constructor(rootStore) {
    this._rootStore = rootStore;
    console.log('ObservableNoteStore constructor');
    // this.initFromAsyncStorage();
    makeAutoObservable(this);
  }

  async initFromAsyncStorage() {
    console.log('initUserFromAsyncStorage ObservableNoteStore');
    // const saved = await AsyncStorage.getItem('@notes');
  }

  async getNotes(pagination) {
    const apiToken = await this._rootStore.userStore.token();
    if (!apiToken) return false;
    const res = await getNotesRequest(apiToken, pagination.pageSize, pagination.current);
    console.log('received notes', res, res.length);
    if (!res) return false;
    runInAction(() => {
      // notes.forEach((note) => {
      //   const existsNote = this.notes.find(x => x.id === note.id);
      //   if (!existsNote || existsNote.modifiedAt != note.modifiedAt) {
      //     this.notes.push(note);
      //     this.hash++;
      //   }
      // });
      this.notesListTotalCount = res.count;
      this.notes = [...res.items];
      console.log('Finish notes', res.items, this.notes.length);
    })
    return true;
  }

  async saveNote(note) {
    const apiToken = await this._rootStore.userStore.token();
    if (!apiToken) return false;

    const savedNote = await saveNoteRequest(apiToken, note);
  }

  changeNote = (note) => {
    runInAction(() => {

      const newNotes = this.notes.map(item => {
        if (item.id === note.id) {
          return item = note
        }
        return item
      })
      this.notes = newNotes
    })
  }

  async patchNote(id, note) {
    try {
      const apiToken = this._rootStore.userStore.user.apiToken
      if (apiToken) {
        const res = await patchNoteRequest(id, note)
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  async getNoteImageUrls(id) {
    try {
      const apiToken = await this._rootStore.userStore.token()
      if (apiToken) {
        const res = await getNoteImageUrlsRequest(id, apiToken)
        console.log('images',res);
      }
    }
    catch (e) {
      console.log(e);
    }
  }
}

export default ObservableNoteStore
