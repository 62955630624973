import {
  action,
  makeAutoObservable,
  makeObservable,
  observable,
  runInAction,
} from 'mobx'

class observerTransactionsStore {
  _rootStore
  transactionsList = [];
  transactionsListTotalCount = 0;

  constructor(rootStore) {
    this._rootStore = rootStore;
    // this.initFromAsyncStorage();
    makeAutoObservable(this);
  }

  async getTransactions(pagination) {
    const token = await this._rootStore.userStore.token();
    const res = await this._rootStore.apiStore.request({
      path: `/transactions?take=${pagination.pageSize}&page=${pagination.current}`,
      method: "GET",
      withToken: true
    });
    console.log('getTransactions', res);
    if (res) {
      runInAction(() => {
        this.transactionsList = res.items;
        this.transactionsListTotalCount = res.count;
      })
    }
    return res;
  }
}

export default observerTransactionsStore