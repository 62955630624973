import ObservableUserStore from "./observableUserStore";
import ObservableNoteStore from "./observableNoteStore";
import ObserverTransactionsStore from "./observerTransactionsStore";
import ObserverSubscriptionsStore from "./observerSubscriptionsStore";
import ObservablePromoCodesStore from "./observerPromoCodesStore";
import ObservableCommonStore from "./observableCommonStore";
import ObservablePushNotificationStore from "./observablePushNotificationStore";
import ApiStore from "./ApiStore";

// console.log('remotedev', remotedev)
// export interface IRootStore {
//   userStore: ObservableUserStore,
// }

export class RootStore {
  constructor() {
    this.userStore = new ObservableUserStore(this);
    this.noteStore = new ObservableNoteStore(this);
    this.transactionsStore = new ObserverTransactionsStore(this);
    this.subscriptionsStore = new ObserverSubscriptionsStore(this);
    this.promoCodesStore = new ObservablePromoCodesStore(this);
    this.commonStore = new ObservableCommonStore(this);
    this.apiStore = new ApiStore(this);
    this.pushNotifictionStore = new ObservablePushNotificationStore(this);
  }
}

export default new RootStore();
console.log("this", this);
