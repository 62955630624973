import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { 
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import Icon from 'components/util-components/Icon';
import store from '../../store';

const menuItem = [
	{
		title: "Пункт меню №1",
		icon: EditOutlined ,
		path: "/"
    },
    
    {
		title: "Пункт меню №2",
		icon: SettingOutlined,
		path: "/"
    },
  //   {
	// 	title: "Billing",
	// 	icon: ShopOutlined ,
	// 	path: "/"
	// },
  //   {
	// 	title: "Help Center",
	// 	icon: QuestionCircleOutlined,
	// 	path: "/"
	// }
]

export const NavProfile = () => {
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={store.userStore?.user?.avatar} />
          <div className="pl-3">
            <h4 className="mb-0">{store.userStore?.user?.email}</h4>
            <span className="text-muted">{store.userStore?.user?.phoneNumber}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => store.userStore.userLogout()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Выйти</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={store.userStore?.user?.avatar} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default observer(NavProfile);