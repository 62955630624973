import {
  makeAutoObservable,
  runInAction,
} from 'mobx'
import { getPromoCodesSeriesRequest, savePromoCodeSerieRequest, getPromoSerieCodesRequest, getPromoActivatedListRequest, managePromoSerieRequest, blockPromoCodeRequest, getCustomizationRequest, saveCustomizationRequest, uploadCustomizationImageRequest, getPromoSerieByIdRequest, getPromoCodesPrefilledSerieRequest, getCustomizationByIdRequest } from './requests/promoCodesStoreRequests';

class observerPromoCodesStore {
  _rootStore
  promoSeriesList = [];
  promoSeriesListTotalCount = 0;
  promoActivatedList = [];
  promoActivatedListTotalCount = 0;

  constructor(rootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }

  async getPromoSeries(data) {
    const token = await this._rootStore.userStore.token();
    const res = await getPromoCodesSeriesRequest(token, data);
    console.log('getPromoSeriesList', res?.items, res?.count);
    if (res) {
      runInAction(() => {
        console.log('getPromoSeriesList action')
        this.promoSeriesList = res.items;
        this.promoSeriesListTotalCount = res.count;
      })
    }
  }
 /**
   * Получает настройки конфигурации по ID.
   *
   * @param {number} id - ID настройки для получения.
   * @return {object} Полученные данные настройки конфигурации.
   */
  async getCustomizationById(id) {
    const res = await getCustomizationByIdRequest(id);

    return res
  }

  async getPrefilledSerie() {
    const token = await this._rootStore.userStore.token();
    const res = await getPromoCodesPrefilledSerieRequest(token);
    return res
  }

  async savePromoSerie(values) {
    const token = await this._rootStore.userStore.token();
    const res = await savePromoCodeSerieRequest(token, values);
    console.log('savePromoCodeSerieRequest', res);
    // if (res) {
    //   runInAction(() => {
    //     this.promoSeriesList = res.items;
    //     this.promoSeriesListTotalCount = res.count;
    //   })
    // }
    return res
  }

  async getPromoSerieCodes(id) {
    const token = await this._rootStore.userStore.token();
    const res = await getPromoSerieCodesRequest(token, id);
    console.log('getPromoSerieCodes', res);
    return res;
  }

  async saveCustomization(values, id) {
    const token = await this._rootStore.userStore.token();
    const res = await saveCustomizationRequest(token, values, id);
    console.log('saveCustomization', res);
    return res;
  }

  async getCustomization(id) {
    const token = await this._rootStore.userStore.token();
    const res = await getCustomizationRequest(token, id);
    console.log('getCustomization', res);
    return res;
  }

  async uploadCustomizationImage(id, data, imageType) {
    const token = await this._rootStore.userStore.token();
    const res = await uploadCustomizationImageRequest(token, id, data, imageType);
    console.log('uploadCustomizationImage', res);
    return res;
  }

  async getPromoActivatedList(id, pagination, query) {
    console.log('getPromoActivatedList start', id, pagination, query)
    const token = await this._rootStore.userStore.token();
    let where = {
      take: pagination?.pageSize || 10,
      page: pagination?.current || 1,
      text: query?.textQuery || null,
      statuses: query?.promoCodesStatuses || null
    }
    const res = await getPromoActivatedListRequest(token, id, where);
    console.log('getPromoActivatedList', res.items, res.count);
    if (res) {
      runInAction(() => {
        console.log('getPromoSeriesList action')
        this.promoActivatedList = res.items;
        this.promoActivatedListTotalCount = res.count;
      })
    }
  }

  async managePromoSerie(id, command) {
    const token = await this._rootStore.userStore.token();
    const res = await managePromoSerieRequest(token, id, command);
    console.log('managePromoSerie', res);
    if (res) {
      runInAction(() => {
        const existsPromoSeries = [...this.promoSeriesList];
        const idx = existsPromoSeries.findIndex(x => x.id === res.id);
        if (idx > -1) {
          existsPromoSeries[idx] = res;
          this.promoSeriesList = existsPromoSeries;
        }
      })
    }
    return res;
  }

  async blockPromoCode(serie, value) {
    const token = await this._rootStore.userStore.token();
    const res = await blockPromoCodeRequest(token, serie, value);
    console.log('blockPromoCode', res);
    if (res) {
      runInAction(() => {
        const existsList = [...this.promoActivatedList];
        const promoIdx = existsList.findIndex(x => x.value == res.value);
        console.log('promoIdx', existsList, existsList[promoIdx])
        if (promoIdx > -1) {
          existsList[promoIdx] = res;
          this.promoActivatedList = existsList;
          console.log('we are here', this.promoActivatedList)
        }
      })
    }
    return res;
  }

  async getPromoSerieById(id, isOnlyFromBackend = false) {
    let promoSerie;

    if (!isOnlyFromBackend) {
      promoSerie = this.promoSeriesList.find(x => x.id == id);
      if (promoSerie) {
        return promoSerie;
      }
    }

    const token = await this._rootStore.userStore.token();
    const res = await getPromoSerieByIdRequest(token, id);
    console.log('getPromoSerieById', res);
    if (res) {
      return res;
    }

    return null;
  }
}

export default observerPromoCodesStore
