import { makeAutoObservable, runInAction } from "mobx";
import {
  getPushNotificationsRequest,
  savePushNotificationRequest,
  cancelPushNotificationRequest,
} from "./requests/pushNotificationStoreRequests";

class observablePushNotificationStore {
  _rootStore;
  pushNotificationsList = [];
  pushNotificationsTotalCount = 0;

  constructor(rootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }

  async getPushNotificationsList({ startDate, page, take, endDate, type }) {
    console.log("getPushNotificationsList params", {
      startDate,
      page,
      take,
      endDate,
      type,
    });
    const token = await this._rootStore.userStore.token();
    const res = await getPushNotificationsRequest({
      token,
      page,
      take,
      startDate,
      endDate,
      type,
    });
    console.log("getPushNotificationsRequest", res.items, res.count);
    if (res) {
      runInAction(() => {
        console.log("getPushNotificationsRequest action", res);
        this.pushNotificationsList = res.items;
        this.pushNotificationsTotalCount = res.count;
      });
    }
  }

  //   async getTgCategories() {
  //     if (this.tgCategories.length > 0) return;
  //     const token = await this._rootStore.userStore.token();
  //     const res = await getTgCategoriesRequest(token);
  //     if (res?.items) {
  //       runInAction(() => {
  //         console.log("getTgCategories action", res);
  //         this.tgCategories = res.items;
  //       });
  //     }
  //   }

  //   async addTgCategorie(value) {
  //     const token = await this._rootStore.userStore.token();
  //     const res = await updateTgCategorieRequest(token, value);
  //     console.log("addTgCategorie", value, res);
  //     if (res) {
  //       runInAction(() => {
  //         this.tgCategories = [...this.tgCategories, res];
  //       });
  //       return res;
  //     }
  //   }

  async savePushNotification(values) {
    const token = await this._rootStore.userStore.token();
    const res = await savePushNotificationRequest(token, values);
    console.log("savePushNotification", res);
    if (res) {
      return res;
    } else {
      return false;
    }

    // if (res) {
    //   runInAction(() => {
    //     if (values.id) {
    //       const existsChannels = [...this.tgChannelsList];
    //       const index = existsChannels.findIndex((x) => x.id === res.id);
    //       existsChannels[index] = res;

    //       this.tgChannelsList = existsChannels;
    //     } else {
    //       this.tgChannelsList = [...this.tgChannelsList, res];
    //       this.tgChannelsListTotalCount = this.tgChannelsListTotalCount++;
    //     }
    //   });
    //   return res;
    // }
  }

  async cancelPushNotification(id) {
    const token = await this._rootStore.userStore.token();
    const res = await cancelPushNotificationRequest(token, id);
    console.log('cancelPushNotificationRequest', res);
    if (res) {
      return res;
    } else {
      return false;
    }
  }

  //   async savePrivatePolicy(values) {
  //     const token = await this._rootStore.userStore.token();
  //     const res = await saveUserAgreementRequest(token, values);
  //     console.log("savedPrivatePolicy result", res);
  //     if (res) {
  //       let savedAgreements = [...this.agreements];
  //       if (values.id) {
  //         const idx = savedAgreements.findIndex((x) => x.id === values.id);
  //         savedAgreements[idx] = res;
  //       } else {
  //         savedAgreements.push(res);
  //       }
  //       runInAction(() => {
  //         this.agreements = [...savedAgreements];
  //       });
  //       return true;
  //     }
  //   }

  //   async getUserAgreements() {
  //     const res = await getUserAgreementsRequest();
  //     console.log("getUserAgreements", res);
  //     if (res) {
  //       runInAction(() => {
  //         this.agreements = res;
  //       });
  //     }
  //   }

  //   async getDashboardData() {
  //     const token = await this._rootStore.userStore.token();
  //     const res = await getDashboardDataRequest(token);
  //     return res;
  //   }

  //   async uploadTGChanelIcon(id, data) {
  //     const token = await this._rootStore.userStore.token();
  //     const res = await uploadTGChanelIconRequest(token, id, data);
  //     return res;
  //   }
}

export default observablePushNotificationStore;
