import { getAvatarUrl } from 'common/utils';
import { AVATARS_BASE_URL } from 'configs/AppConfig';
import {
  action,
  makeAutoObservable,
  makeObservable,
  observable,
  runInAction,
} from 'mobx'
import {
  signInRequest,
  getUsersRequest,
  saveUserRequest,
  getUsersForExportRequest,
  refreshTokenRequest,
  getDefinedUserRequest,
  manageUserStatusRequest
} from './requests/userStoreRequests';

class ObservableUserStore {
  _rootStore
  user = null;
  users = [];
  usersList = [];
  usersListTotalCount = 0;
  constructor(rootStore) {
    this._rootStore = rootStore;
    console.log('ObservableUserStore constructor', this.user);
    this.initFromAsyncStorage();
    makeAutoObservable(this);
  }

  get isAuth() {
    console.log('Computing...', this.user ? this.user.apiToken : null);
    if (this.user && this.user.apiToken) {
      return true;
    }
    return false;
  }

  async initFromAsyncStorage() {
    console.log('initUserFromAsyncStorage');
    const saved = await localStorage.getItem('@user');
    const user = saved ? JSON.parse(saved) : null;
    console.log('Saved User', saved, user);
    if (!user || !user.apiToken) {
      runInAction(() => {
        this.user = null;
      });
    } else {
      runInAction(() => {
        this.user = user;
      });
    }
  }

  async userSignIn({
    email,
    password
  }) {
    const userData = await signInRequest({
      email,
      password
    });
    console.log('User Data', userData);
    if (!userData) return {
      error: true,
      message: "Неправильный логин или пароль."
    }
    if (userData.role != "admin" && userData.role != "editor") return {
      error: true,
      message: "Пользователю с данной ролью вход запрещен."
    }
    userData.apiTokenExpiresAt = Date.now() + 3600 * 1000;

    runInAction(() => {
      this.user = userData;
    });
    localStorage.setItem('@user', JSON.stringify(userData));
    return {
      error: false,
      message: "OK"
    }
  }

  userLogout() {
    console.log('userLogout()');
    runInAction(() => {
      this.user = null;
    });
    localStorage.removeItem('@user');
  }

  async getUsers(pagination: {
    current: number,
    pageSize: number
  }, formParams: any) {
    const token = await this.token()
    if (token) {
      const response = await getUsersRequest(token, pagination.current, pagination.pageSize, formParams)
      console.log('getUsers response', response?.items?.length, response?.count)
      runInAction(() => {
        this.usersList = response?.items
        this.usersListTotalCount = Number(response?.count)
      })
    }
  }

  async getUsersForExport() {
    const token = await this._rootStore.userStore.token();
    const res = await getUsersForExportRequest(token);
    console.log('getUsersForExport', res);
    const newRes = res.map((item) => {
      if (item.avatar && !item.avatar.startsWith('http')) {
        item.avatar = AVATARS_BASE_URL + '/' + item.avatar
      }
      return item
    })
    return newRes;
  }

  async saveUser(id: string, data: {
    defaultPlace: string,
    dob: Date,
    email: string,
    name: string,
    phoneNumber: string,
    role: "superadmin" | "admin" | "cutomer"
  }) {
    const token = await this.token()
    if (token) {
      const response = await saveUserRequest(token, id, data)
      console.log('saveUser response', response)

      if (response) {
        const newList = [...this.usersList]
        const user = newList.find(x => x.id === id)
        console.log('saved user in list', user)
        newList[newList.indexOf(user)] = response
        console.log('new list', newList)
        runInAction(() => {
          this.usersList = [...newList]
          this.usersListTotalCount = Number(response?.count)
        })
      }
      return response
    }
  }

  async getUser(id: string) {
    const token = await this.token()
    if (token) {
      const response = await getDefinedUserRequest(token, id)
      console.log('getUser response', response)

      if (response) {
        const newList = [...this.usersList]
        const user = newList.find(x => x.id === id)
        if (user) {
          console.log('saved user in list', user)
          newList[newList.indexOf(user)] = response
          console.log('new list', newList)
        } else {
          newList.push(response);
        }
        runInAction(() => {
          this.usersList = [...newList]
          this.usersListTotalCount = user ? this.usersListTotalCount : this.usersListTotalCount++;
        })
      }
      return response
    }
  }

  async token() {
    console.log('get token', this.user.apiTokenExpiresAt);
    try {
      if (!this.user) return null;
      if (!this.user.refreshToken) {
        console.log('get token no refresh token')
        runInAction(() => {
          this.user = null;
        })
        return null;
      }
      if (this.user.apiTokenExpiresAt < Date.now() + 5000) {
        console.log('get token retrieve data')
        const user = await refreshTokenRequest(this.user.refreshToken);
        console.log('get token data retrieved', user);
        if (user) user.apiTokenExpiresAt = Date.now() + 3600 * 1000;
        runInAction(() => {
          this.user = user;
        })
        localStorage.setItem('@user', JSON.stringify(user));
        return user ? user.apiToken : null;
      }
      console.log('get token all ok')
      return this.user.apiToken;
    } catch (e) {
      console.log("Error in Token retrieving", e);
      return null;
    }
  }

  updateCustomerRegister(userId, register) {
    const newUsersList = [...this.usersList];
    const existsUser = newUsersList.find(x => x.id === userId);
    console.log('updateCustomerRegister', existsUser);
    if (!existsUser) return false;
    existsUser.register = register;
    console.log('updateCustomerRegister result')
    runInAction(() => {
      this.usersList = newUsersList;
    })
    return true;
  }

  async manageUserStatus(userId, command) {
    const token = await this.token();

    if (token) {
      const response = await manageUserStatusRequest(token, userId, command)
      console.log('manageUserStatus response', response)

      if (response) {
        const newList = [...this.usersList]
        const user = newList.find(x => x.id === userId)
        if (user) {
          console.log('manageUserStatus user in list', user)
          newList[newList.indexOf(user)] = response
          console.log('new list', newList)
        } else {
          newList.push(response);
        }
        runInAction(() => {
          this.usersList = [...newList]
          this.usersListTotalCount = user ? this.usersListTotalCount : this.usersListTotalCount++;
        })
      }
      return response
    }
  }

  userLoggedIn() {
    return new Promise(res => {
      let count = 0;
      setInterval(() => {
        count++;
        if (this.user) res(this.user);
        if (count > 5) res(null);
      }, 300);
    })
  }
}

export default ObservableUserStore