import { IRootStore } from ".";
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { API_GATEWAY } from '../configs/AppConfig'

class RequestFactory {
  _rootStore;

  constructor(rootStore) {
    this._rootStore = rootStore;
  }

  async request({ method, path, body, successStatus, responseType, withToken }) {

    const url = API_GATEWAY + path;
    const opts: AxiosRequestConfig = {
      url,
      method,
      // mode: "cors",
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    }

    if (body) opts.data = JSON.stringify(body);

    if (withToken) {
      const { user } = this._rootStore.userStore;
      console.log("with token current user", user);
      if (!user || !user.apiToken) return null;
      opts.headers = { ...opts.headers, "Api-Token": `${user.apiToken}` }
    }

    console.log("Request final opts", opts);

    return await axios(opts).then((res) => {
      console.log('Fetch performed', opts, res.status);

      if (res.status === (successStatus || 200)) {
        // return res.data;
        if (responseType) {
          if (responseType === "json") return res.data;
          if (responseType === "text") return res.data;
        } else {
          console.log("response", res);
          return res.data;
        }
      }
      return null;
    }).catch((err) => {
      console.log('Error in Perform Request', { opts, err });
      const { status } = err?.response || {};
      if (status === 401) {
        this._rootStore.userStore.logOut();
      }
      return null;
    })
  }
}

export default RequestFactory;
