import {
    action,
    makeAutoObservable,
    makeObservable,
    observable,
    runInAction,
  } from 'mobx'
import { getSubscriptionsRequest, getSubscriptionsDescsRequest, uploadSubIconRequest, saveSubDescRequest, manageSubDescRequest, activateSubForUserRequest } from './requests/subscriptionsStoreRequests';

class observerSubscriptionsStore {
  _rootStore
  subscriptionsList = [];
  subscriptionsListTotalCount = 0;
  subscriptionsDescsList = [];
  subscriptionsDescsListTotalCount = 0;

  constructor(rootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }
  
  async getSubscriptions(pagination) {
    const token = await this._rootStore.userStore.token();
    const res = await getSubscriptionsRequest(token, pagination.current, pagination.pageSize);
    console.log('getSubscriptions', res?.items, res?.additionalInfo?.relatedUsers);
    if (res) {
      runInAction(() => {
        this.subscriptionsList = res.items.map(sub => {return {...sub, owner: res.additionalInfo?.relatedUsers?.find(x => x.id == sub.owner)}});
        this.subscriptionsListTotalCount = res.count;
      })
    }
  }

  async getSubscriptionsDescs() {
    const token = await this._rootStore.userStore.token();
    const res = await getSubscriptionsDescsRequest(token);
    console.log('getSubscriptionsDescs', res);
    if (Array.isArray(res)) {
      runInAction(() => {
        this.subscriptionsDescsList = res.sort((a, b) => a.id - b.id);
        this.subscriptionsDescsListTotalCount = res.length;
      })
      return res;
    }
    return null;
  }

  async saveSubDesc(data) {
    const token = await this._rootStore.userStore.token();
    const res = await saveSubDescRequest(token, data);
    if (res) {
      runInAction(() => {
        const subs = [...this.subscriptionsDescsList]
        const idx = subs.findIndex(x => x.id === res.id);
        if (idx >= 0) {
          subs[idx] = res;
          this.subscriptionsDescsList = subs;
        } else {
          this.subscriptionsDescsList = [...this.subscriptionsDescsList, res];
          this.subscriptionsDescsListTotalCount++;
        }
      })
    }
    return res;
  }

  async uploadSubIcon(id, data) {
    const token = await this._rootStore.userStore.token();
    const res = await uploadSubIconRequest(token, id, data);
    return true;
  }

  async manageSubDesc(id, command) {
    const token = await this._rootStore.userStore.token();
    const res = await manageSubDescRequest(token, id, command);
    console.log('manageSubDesc', res);
    if (res) {
      runInAction(() => {
        const subs = [...this.subscriptionsDescsList]
        const idx = subs.findIndex(x => x.id === res.id);
        subs[idx] = res;
        this.subscriptionsDescsList = subs;
      })
    }
  }

  async activateSubForUser(userId, subDescId) {
    const token = await this._rootStore.userStore.token();
    const res = await activateSubForUserRequest(token, subDescId, userId);
    console.log('activateSubForUser', res);
    if (res) {
      this._rootStore.userStore.updateCustomerRegister(userId, res);
    }
    return res;
  }
}
  
export default observerSubscriptionsStore
  