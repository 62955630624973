import { AVATARS_BASE_URL } from "configs/AppConfig";

export const getLocaleMonth = (date) => {
  switch (date) {
    case 1:
      return "Январь";
    case 2:
      return "Февраль";
    case 3:
      return "Март";
    case 4:
      return "Апрель";
    case 5:
      return "Май";
    case 6:
      return "Июнь";
    case 7:
      return "Июль";
    case 8:
      return "Август";
    case 9:
      return "Сентябрь";
    case 10:
      return "Октябрь";
    case 11:
      return "Ноябрь";
    case 12:
      return "Декабрь";
    default:
      return "";
  }
};

export function formatDate(date, withTime) {
  // if (typeof date === 'undefined') {
  //   return;
  // }

  // if (
  //   typeof date === 'number' ||
  //   typeof date === 'string' ||
  //   typeof date === 'undefined' ||
  //   !(date instanceof Date)
  // ) {
  //   if (date) {
  //     if (date.toString().length < 12) {
  //       date = Number(date) * 1000;
  //     }
  //   }
  //   const dateObj = new Date(date ? date : new Date(0));
  //   let day = dateObj.getDate();
  //   day < 10 ? (day = '0' + day) : null;
  //   let month = (dateObj.getMonth() + 1);
  //   month < 10 ? (month = '0' + month) : null;
  //   let year = dateObj.getFullYear();
  //   if (withTime) {
  //     let hours = dateObj.getHours().toString();
  //     if (parseInt(hours) < 10) {
  //       hours = '0' + hours;
  //     }
  //     let minutes = dateObj.getMinutes().toString();
  //     if (parseInt(minutes) < 10) {
  //       minutes = '0' + minutes;
  //     }
  //     return { day, month, year, hours, minutes };
  //   }
  //   return { day, month, year };
  // } else {
  //   let day: number | string = date.getDate();
  //   day < 10 ? (day = '0' + day) : null;
  //   let month: number | string = date.getMonth() + 1;
  //   month < 10 ? (month = '0' + month) : null;
  //   const year = date.getFullYear()
  //   if (withTime) {
  //     let hours = date.getHours().toString();
  //     if (parseInt(hours) < 10) {
  //       hours = '0' + hours;
  //     }
  //     let minutes = date.getMinutes().toString();
  //     if (parseInt(minutes) < 10) {
  //       minutes = '0' + minutes;
  //     }
  //     return { day, month, year, hours, minutes };
  //   }
  //   return { day, month, year };
  // }
  return {
    day: new Date(date).getDay,
    month: new Date(date).getMonth,
    year: new Date(date).getFullYear,
  };
}

export function formatDateWithYear(date) {
  // const today = new Date(Date.now());
  // if (
  //   typeof date === 'number' ||
  //   typeof date === 'string' ||
  //   typeof date === 'undefined' ||
  //   !(date instanceof Date)
  // ) {
  //   if (date) {
  //     if (date.toString().length < 12) {
  //       date = Number(date) * 1000;
  //     }
  //   }
  //   const dateObj = new Date(date ? date : new Date(0));
  //   let day = dateObj.getDate().toString();
  //   let month: number | string = dateObj.getMonth() + 1;
  //   const year = dateObj.getUTCFullYear();
  //   if (parseInt(day) < 10) {
  //     day = '0' + day;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }
  //   let hours = dateObj.getHours().toString();
  //   if (parseInt(hours) < 10) {
  //     hours = '0' + hours;
  //   }
  //   let minutes = dateObj.getMinutes().toString();
  //   if (parseInt(minutes) < 10) {
  //     minutes = '0' + minutes;
  //   }
  //   if (
  //     Number(day) == today.getDate() &&
  //     Number(month) == today.getMonth() + 1
  //   ) {
  //     return `${hours}:${minutes}`;
  //   } else {
  //     return `${year}-${month}-${day}`;
  //   }
  // } else {
  //   return '';
  // }
  return `${new Date(date).getFullYear}-${new Date(date).getMonth}-${
    new Date(date).getDay
  }`;
}

export const getAvatarUrl = (avatar) => {
  if (avatar) {
    if (avatar.startsWith("http")) return avatar;
    return AVATARS_BASE_URL + "/" + avatar;
  } else {
    return "/img/avatars/no-user-avatar.svg";
  }
};

export const formatPhoneNumber = (phone) => {
  if (!phone || typeof phone != "string") return "";
  const formatted = phone.match(/(\-)|(\))/g)?.length > 0 ? true : false;
  const val = phone.replace(/\D/g, "");
  const mask = `+${val.length >= 1 ? val[0] : 7}-${val[1] || ""}${
    val[2] || ""
  }${val[3] || ""}-${val[4] || ""}${val[5] || ""}${val[6] || ""}-${
    val[7] || ""
  }${val[8] || ""}${val[9] || ""}${val[10] || ""}`;
  console.log("formatPhoneNumber", phone, phone.length, val, mask);
  return mask
    .substr(
      0,
      formatted
        ? phone.length > 4
          ? phone.length + 1
          : phone.length
        : mask.length
    )
    .replace(/(\-|\))*$/, "");
};

export const createUrlQueryFromObject = (obj) => {
  var str = [];
  for (var p in obj) {
    if (obj.hasOwnProperty(p) && !!obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.join("&");
};
