import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
// import { IntlProvider } from "react-intl";
// import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import { observer } from 'mobx-react-lite';
import { useHistory } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import mobxStore from '../store'

export const Views = observer((props) => {
  const history = useHistory()
  const { location } = props;
  const [ locale, setLocale ] = useState("ru");
  const [ direction, setDirection ] = useState("ltr");
  const [ isLoading , setIsLoading ] = useState(true);

  console.log({ location });
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-ltr`);
  
  useEffect(() => {
    console.log('Views this', window, document);
    window.mobxStore = mobxStore;

    (async () => {
      const user = await mobxStore.userStore.userLoggedIn();
      console.log("mobxStore.userStore.userLoggedIn", user);
      setIsLoading(false)
      if (user) {
        console.log("next location", location?.pathname?.startsWith('/app') ? location : '/app/home' || '/app/home');
        history.push(location?.pathname?.startsWith('/app') ? location : '/app/home' || '/app/home');
        mobxStore.subscriptionsStore.getSubscriptionsDescs();
        mobxStore.commonStore.getUserAgreements();
      } else {
        history.push('/auth/login')
      }
    })();

  }, [])

  useEffect(() => {
    console.log("mobxStore.userStore.user")
    if (isLoading) return false;
    if (mobxStore.userStore.user && (mobxStore.userStore.user.role === 'admin' || mobxStore.userStore.user.role === 'editor') && !isLoading) {
      history.push('/app/home');
      mobxStore.subscriptionsStore.getSubscriptionsDescs();
      mobxStore.commonStore.getUserAgreements();
    }
    else{
      history.push('/auth/login')
    }
  }, [mobxStore.userStore.user])

  return (
    // <IntlProvider
      // locale={locale}
      // messages={currentAppLocale.messages}>
      // <ConfigProvider locale={locale} direction={direction}>
    <>
      {isLoading && 
        <Loading cover="content"/>
      }
      {!isLoading &&
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>          
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location} />
          </Route>
        </Switch>
      }
    </>
      // </ConfigProvider>
    // </IntlProvider>
  )
})

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));