import { API_GATEWAY } from "configs/AppConfig"

export const getNotesRequest = async (apiToken, take: number, page: number, query?: string) => {
  let path = `${API_GATEWAY}/notes`;
  let divider = '?';
  if (take) {
    path += `${divider}take=${take}`;
    divider = '&'
  }
  if (page) {
    path += `${divider}page=${page}`;
    divider = '&';
  }
  if (query) path += `${divider}query=${query}`;
  console.log('paht', path);
  
  return await fetch(path, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      'Api-Token': apiToken
    }
  }).then(res => {
    console.log('getNotesRequest result', res.status);
    if (res.status === 200) return res.json();
    return [];
  });
}

export const saveNoteRequest = async (apiToken, note) => {
  if (!note.id) {
    const req = await fetch(`${API_GATEWAY}/notes`, {
      method: "POST",
      mode: 'cors',
      headers: {
        accept: '*/*',
        'Content-Type': 'application/json',
        'Api-Token': apiToken
      },
      body: JSON.stringify(note)
    }).then(async res => {
      console.log('saveNoteRequest result', res.status);
      console.log('res', await res);
      if (res.status === 200) return res.json();
      return null;
    });

    return req;
  } else {

  }

}

export const patchNoteRequest = async (id, note, apiToken) => {
  return await fetch(`${API_GATEWAY}/notes/${id}`, {
    method: "PATCH",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      'Api-Token': apiToken
    },
    body: JSON.stringify(note)
  }).then(res => {
    console.log('patchNoteRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}


export const getNoteImageUrlsRequest = async (id, apiToken) => {
  return await fetch(`${API_GATEWAY}/notes/${id}/image-url/png`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      'Api-Token': apiToken
    },
  }).then(res => {
    console.log('getNoteImageUrlsRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}