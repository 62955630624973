import {
  action,
  makeAutoObservable,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  getTGChannelsRequest,
  saveTGChannelRequest,
  deleteTGChannelRequest,
  getUserAgreementsRequest,
  saveUserAgreementRequest,
  getDashboardDataRequest,
  getTgCategoriesRequest,
  updateTgCategorieRequest,
  uploadTGChanelIconRequest,
} from "./requests/commonStoreRequests";

class observableCommonStore {
  _rootStore;
  tgChannelsList = [];
  tgChannelsListTotalCount = 0;
  agreements = [];
  tgCategories = [];

  constructor(rootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }

  async getTGChannels() {
    const token = await this._rootStore.userStore.token();
    const res = await getTGChannelsRequest(token, 0, 1000);
    console.log("getPromoSeriesList", res.items, res.count);
    if (res) {
      runInAction(() => {
        console.log("getTGChannelsList action", res);
        this.tgChannelsList = res.items;
        this.tgChannelsListTotalCount = res.count;
      });
    }
  }

  async getTgCategories() {
    if (this.tgCategories.length > 0) return;
    const token = await this._rootStore.userStore.token();
    const res = await getTgCategoriesRequest(token);
    if (res?.items) {
      runInAction(() => {
        console.log("getTgCategories action", res);
        this.tgCategories = res.items;
      });
    }
  }

  async addTgCategorie(value) {
    const token = await this._rootStore.userStore.token();
    const res = await updateTgCategorieRequest(token, value);
    console.log("addTgCategorie", value, res);
    if (res) {
      runInAction(() => {
        this.tgCategories = [...this.tgCategories, res];
      });
      return res;
    }
  }

  async saveTGChannel(id, values) {
    const token = await this._rootStore.userStore.token();
    const res = await saveTGChannelRequest(token, values);
    console.log("saveTGChannel", res);
    if (res) {
      runInAction(() => {
        if (values.id) {
          const existsChannels = [...this.tgChannelsList];
          const index = existsChannels.findIndex((x) => x.id === res.id);
          existsChannels[index] = res;

          this.tgChannelsList = existsChannels;
        } else {
          this.tgChannelsList = [...this.tgChannelsList, res];
          this.tgChannelsListTotalCount = this.tgChannelsListTotalCount++;
        }
      });
      return res;
    }
  }

  async deleteTGChannel(id) {
    const token = await this._rootStore.userStore.token();
    const res = await deleteTGChannelRequest(token, id);
    if (res) {
      runInAction(() => {
        this.tgChannelsList = [
          ...this.tgChannelsList.filter((x) => x.id !== id),
        ].sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt));
        this.tgChannelsListTotalCount = this.tgChannelsList.length;
      })
    }
    console.log("deleteTGChannel", res);
    return res;
  }

  async savePrivatePolicy(values) {
    const token = await this._rootStore.userStore.token();
    const res = await saveUserAgreementRequest(token, values);
    console.log("savedPrivatePolicy result", res);
    if (res) {
      let savedAgreements = [...this.agreements];
      if (values.id) {
        const idx = savedAgreements.findIndex((x) => x.id === values.id);
        savedAgreements[idx] = res;
      } else {
        savedAgreements.push(res);
      }
      runInAction(() => {
        this.agreements = [...savedAgreements];
      });
      return true;
    }
  }

  async getUserAgreements() {
    const res = await getUserAgreementsRequest();
    console.log("getUserAgreements", res);
    if (res) {
      runInAction(() => {
        this.agreements = res;
      });
    }
  }

  async getDashboardData() {
    const token = await this._rootStore.userStore.token();
    const res = await getDashboardDataRequest(token);
    return res;
  }

  async uploadTGChanelIcon(id, data) {
    const token = await this._rootStore.userStore.token();
    const res = await uploadTGChanelIconRequest(token, id, data);
    return res;
  }
}

export default observableCommonStore;
