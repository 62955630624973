import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./default`))} />
        <Route path={`${APP_PREFIX_PATH}/notes`} component={lazy(() => import(`./notes`))} />
        <Route path={`${APP_PREFIX_PATH}/transactions`} component={lazy(() => import('./transactions'))} />
        <Route path={`${APP_PREFIX_PATH}/subscriptions`} component={lazy(() => import(`./subscriptions`))} />
        <Route path={`${APP_PREFIX_PATH}/common/tg-channels`} component={lazy(() => import(`./common/tg-channels/index`))} />
        <Route path={`${APP_PREFIX_PATH}/telegram-chanels`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users/users-list`))} />
        <Route path={`${APP_PREFIX_PATH}/promo-codes`} component={lazy(() => import(`./promo-codes/promo-series-list`))} />
        <Route path={`${APP_PREFIX_PATH}/common/privacy-policy`} component={lazy(() => import(`./common/policy`))} />
        <Route path={`${APP_PREFIX_PATH}/common/user-agreement`} component={lazy(() => import(`./common/user-agreement`))} />
        <Route path={`${APP_PREFIX_PATH}/common/settings`} component={lazy(() => import(`./common/settings`))} />
        <Route path={`${APP_PREFIX_PATH}/push-notifications`} component={lazy(() => import(`./push-notifications/push-router`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);