import { API_GATEWAY } from "configs/AppConfig"

export const signInRequest = async ({email, password}) => {
  return await fetch(`${API_GATEWAY}/users/signin`, {
    method: "POST",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json'
    },
    body: JSON.stringify({email, password})
  }).then(res => {
    console.log('signInRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
} 

export const getUsersRequest = async (token, page, take, formParams) => {
  let query = `${API_GATEWAY}/users?take=${take}&page=${page}`;
  if (formParams) {
    query += `&where=${encodeURIComponent(JSON.stringify(formParams))}`
  }

  console.log('getUsersRequest', query);

  return await fetch(query, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getUsersRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const getUsersForExportRequest = async (token) => {
  return await fetch(`${API_GATEWAY}/users/export-data`, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getUsersForExportRequest', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const getDefinedUserRequest = async (token, id) => {
  let query = `${API_GATEWAY}/users/${id}`;

  return await fetch(query, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getDefinedUserRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}


export const saveUserRequest = async (token, id, data) => {
  let query = `${API_GATEWAY}/users`;
  if (id != "new") query += `/${id}`;

  console.log('saveUserRequest', query, data);

  return await fetch(query, {
    method: "POST",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
    body: JSON.stringify(data)
  }).then(res => {
    console.log('saveUserRequest result', res.status);
    if (res.status < 400) return res.json();
    return null;
  });
}

export const refreshTokenRequest = async (token) => {
  return await fetch(`${API_GATEWAY}/users/refresh-token`, {
    method: "PATCH",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json'
    },
    body: JSON.stringify({token})
  }).then(res => {
    console.log('refreshTokenRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
} 

export const manageUserStatusRequest = async (token, userId, command) => {
  let query = `${API_GATEWAY}/users/${userId}/manage/${command}`;

  return await fetch(query, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('manageUserStatusRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}