import {
  DashboardOutlined,
  UserOutlined,
  UnorderedListOutlined,
  ShoppingCartOutlined,
  TransactionOutlined,
  MessageOutlined,
  DisconnectOutlined,
  FileTextOutlined,
  PaperClipOutlined,
  BellOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'main',
  path: `${APP_PREFIX_PATH}/`,
  title: 'Главное меню',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'home',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'Главная',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'users',
      path: `${APP_PREFIX_PATH}/users`,
      title: 'Пользователи',
      icon: UserOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: 'notes',
    //   path: `${APP_PREFIX_PATH}/notes`,
    //   title: 'Заметки',
    //   icon: UnorderedListOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: 'transactions',
      path: `${APP_PREFIX_PATH}/transactions`,
      title: 'Транзакции',
      icon: TransactionOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'subscriptions',
      path: `${APP_PREFIX_PATH}/subscriptions`,
      title: 'Подписки',
      icon: ShoppingCartOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'subscriptions-desc',
          path: `${APP_PREFIX_PATH}/subscriptions/desc`,
          title: 'Доступные подписки',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'subscriptions-item',
          path: `${APP_PREFIX_PATH}/subscriptions/items`,
          title: 'Подписки пользователей',
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'promo-codes',
      path: `${APP_PREFIX_PATH}/promo-codes`,
      title: 'Промо-коды',
      icon: DisconnectOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'telegram-chanels',
      path: `${APP_PREFIX_PATH}/common/tg-channels`,
      title: 'Телеграмм Каналы',
      icon: MessageOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'common',
      path: `${APP_PREFIX_PATH}/common`,
      title: 'Общее',
      icon: PaperClipOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'common-privacy',
          path: `${APP_PREFIX_PATH}/common/privacy-policy`,
          title: 'Соглашение об обработке ПД',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'common-user-agreement',
          path: `${APP_PREFIX_PATH}/common/user-agreement`,
          title: 'Пользовательское соглашение',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'common-settings',
          path: `${APP_PREFIX_PATH}/common/settings`,
          title: 'Настройки',
          breadcrumb: false,
          submenu: []
        },
      ]
    },
    {
      key: 'push-notifications',
      path: `${APP_PREFIX_PATH}/push-notifications`,
      title: 'Пуш уведомления',
      icon: BellOutlined,
      breadcrumb: false,
      submenu: []
    },
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
