import { API_GATEWAY } from "configs/AppConfig"

export const getSubscriptionsRequest = async (token, page, take) => {
  return await fetch(`${API_GATEWAY}/subscriptions?take=${take}&page=${page}`, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getSubscriptionsRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const getSubscriptionsDescsRequest = async (token) => {
  return await fetch(`${API_GATEWAY}/subscriptions/desc`, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getSubscriptionsDescsRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const uploadSubIconRequest = async (token, id, data) => {

  const formData  = new FormData();
  formData.append('icon', data);

  return await fetch(`${API_GATEWAY}/subscriptions/desc/${id}/icon`, {
    method: "POST",
		mode: 'cors',
		headers: {
			accept: '*/*',
			// 'Content-Type': 'multipart/form-data; boundary=CUSTOM',
      "Api-Token": token,
    },
    body: formData
  }).then(res => {
    console.log('getSubscriptionsDescsRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const saveSubDescRequest = async (token, values) => {
  console.log("saveSubDescRequest", values);
  let query = `${API_GATEWAY}/subscriptions/desc`;
  let method = "POST"

  if (values.id) {
    method = "PATCH";
    query += `/${values.id}`;
    delete values.id;
  }

  return await fetch(query, {
    method,
		mode: 'cors',
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json',
      "Api-Token": token,
    },
    body: JSON.stringify(values)
  }).then(res => {
    console.log('saveSubDescRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const manageSubDescRequest = async (token, id, command) => {
  console.log('manageSubDescRequest', id, command)
  return await fetch(`${API_GATEWAY}/subscriptions/desc/${id}/manage/${command}`, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('manageSubDescRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const activateSubForUserRequest = async (token, sku, userId) => {
  console.log('activateSubForUserRequest', sku, userId)
  return await fetch(`${API_GATEWAY}/subscriptions/subscribe-by-admin/${userId}/${sku}`, {
    method: "GET",
		mode: 'cors',
		headers: {
			accept: '*/*',
      "Api-Token": token,
    },
    // body: JSON.stringify({
    //   sku: sku, 
    //   userId: userId
    // })
  }).then(res => {
    console.log('activateSubForUserRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}
