import { API_GATEWAY } from "configs/AppConfig";
import { createUrlQueryFromObject } from '../../common/utils'

export const getPushNotificationsRequest = async ({token, page, take, startDate, endDate, type}) => {
  const query = createUrlQueryFromObject({page, take, startDate, endDate, type});
  return await fetch(
    `${API_GATEWAY}/reminders?${query}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        "Api-Token": token,
      },
    }
  ).then((res) => {
    if (res.status === 200) return res.json();
    return null;
  });
};

export const savePushNotificationRequest = async (token, values) => {
  let query = `${API_GATEWAY}/reminders/create-push-notification`;
  let method = "POST";

  return await fetch(query, {
    method,
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
    body: JSON.stringify(values),
  }).then((res) => {
    console.log("savePushNotificationRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const cancelPushNotificationRequest = async (token, id) => {
  return await fetch(`${API_GATEWAY}/reminders/${id}/cancel`, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
  }).then((res) => {
    console.log("cancelPushNotificationRequest", res.status);
    if (res.status === 200) return true;
    return null;
  });
};
