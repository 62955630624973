import { API_GATEWAY } from "configs/AppConfig";

export const getTGChannelsRequest = async (token, page, take) => {
  return await fetch(
    `${API_GATEWAY}/common/tg-channels?take=${take}&page=${page}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        "Api-Token": token,
      },
    }
  ).then((res) => {
    console.log("getTGChannelsRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const getTgCategoriesRequest = async (token) => {
  return await fetch(`${API_GATEWAY}/common/tg-categories`, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
  }).then((res) => {
    console.log("getTgCategoriesRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const updateTgCategorieRequest = async (token, category) => {
  let query = `${API_GATEWAY}/common/tg-categories`;
  let method = "POST";

  return await fetch(query, {
    method,
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
    body: JSON.stringify(category),
  }).then((res) => {
    console.log("updateTgCategorieRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const saveTGChannelRequest = async (token, values) => {
  let query = `${API_GATEWAY}/common/tg-channels`;
  let method = "POST";

  return await fetch(query, {
    method,
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
    body: JSON.stringify(values),
  }).then((res) => {
    console.log("saveTGChannelRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const deleteTGChannelRequest = async (token, id) => {
  return await fetch(`${API_GATEWAY}/common/tg-channels/${id}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
  }).then((res) => {
    console.log("deleteTGChannelRequest", res.status);
    if (res.status === 200) return true;
    return null;
  });
};

export const getUserAgreementsRequest = async () => {
  return await fetch(`${API_GATEWAY}/common/agreements`, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    console.log("getUserAgreementsRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const saveUserAgreementRequest = async (token, values) => {
  let query = `${API_GATEWAY}/common/agreements`;
  let method = "POST";

  return await fetch(query, {
    method,
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
    body: JSON.stringify(values),
  }).then((res) => {
    console.log("saveUserAgreementRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const getDashboardDataRequest = async (token) => {
  return await fetch(`${API_GATEWAY}/common/dashboard-data`, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "*/*",
      "Content-Type": "application/json",
      "Api-Token": token,
    },
  }).then((res) => {
    console.log("getDashboardDataRequest result", res.status);
    if (res.status === 200) return res.json();
    return null;
  });
};

export const uploadTGChanelIconRequest = async (token, id, data) => {
  const formData = new FormData();
  formData.append("icon", data);

  return await fetch(`${API_GATEWAY}/common/tg-channels/${id}/icon`, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "*/*",
      // 'Content-Type': 'multipart/form-data; boundary=CUSTOM',
      "Api-Token": token,
    },
    body: formData,
  }).then((res) => {
    console.log("uploadTGChanelIconRequest result", res.status);
    if (res.status === 200) return res.text();
    return null;
  });
};
